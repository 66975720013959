<template>
    <div class='bookingBox'>
        <div class='events' v-for='(item, i) in reservations' :key='i'>
            <div class='eventsState textR'>
                <span v-if='!item.reservationId' class='bookingEmpty'>可预约</span>
                <span v-if='item.reservationId' class='BookingHave ftb'>预约成功</span>
            </div>
            <van-card :title='item.name'>
                <template #thumb>
                    <img :src='getThumb(item)' alt='' class='activity-img'>
                </template>
                <template #desc>
                    <van-cell-group>
                        <van-cell class='borderDefault mb10' title=''>
                            <template #label>
                                <div class='distance'>
                                    <van-icon name='location' class=''></van-icon>
                                    <span v-if='!item.distance' class='tplTextDefault'>计算距离中... </span>
                                    <span v-if='item.distance' class='tplTextDefault'>距您{{ item.distance }}km</span>
                                    <span class='tplTextDefault'>{{ item.clinicName }}</span>
                                </div>
                                <div class='duration'>
                                    <span class='tplTextDefault'>活动时间：{{ item.duration }}</span>
                                </div>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </template>
                <template #footer>
                    <van-cell
                        v-if='!item.reservationId'
                        class='textC'
                        size='lager'
                        @click='reserveActivity(item.id)'
                    >
                        <template #title>
                            <van-button class='newBook plr40' round color='#4AB8AB' size='normal'
                            >预约
                            </van-button>
                        </template>
                    </van-cell>
                    <van-cell
                        v-if='item.reservationId'
                        class='textC'
                        size='lager'
                        @click='deleteActivityReservation(item.reservationId)'
                    >
                        <template #title>
                            <van-button class='tplButton Cancel' plain color='#666666'
                            >取消预约
                            </van-button>
                        </template>
                    </van-cell>
                </template>
            </van-card>
        </div>
        <van-empty v-if='!reservations.length' class='emptyBox' description='选择耳宁医疗中心，预约专业服务' />
    </div>
</template>

<script>
import { toast, getImageUrl, calculateDistance, formatDate } from '@/util';
import locationMixin from '../../../mixins/locationMixin';
import {
    deleteActivityReservation,
    getActivitiesForUser,
    reserveActivity
} from '@/services/reservation';

export default {
    name: 'activity-list',
    mixins: [locationMixin],
    data() {
        return {
            eventsPhoto: require('@/assets/images/events.png'),
            reservations: []
        };
    },
    created() {
        this.getActivitiesForUser();
    },
    methods: {
        async getActivitiesForUser() {
            const { code, message, data } = await getActivitiesForUser();
            if (!code) {
                return toast(message);
            }
            data.forEach(r => {
                r.duration = formatDate(r.startAt, 'yyyy-MM-dd HH:mm') + ' ~ ' + formatDate(r.endAt, 'yyyy-MM-dd HH:mm');
                r.distance = 0;
            });
            this.reservations = data;
            if (this.$store.state.location) {
                this.calcDistance();
            } else {
                let location = await this.getLocation();
                this.$store.commit('updateLocation', location);
                this.calcDistance();
            }
        },
        calcDistance() {
            this.reservations.forEach(r => {
                let { longitude, latitude } = this.$store.state.location;
                const distance = calculateDistance(longitude, latitude, r.clinicLng, r.clinicLat);
                if (distance) r.distance = distance;
            });
        },
        async reserveActivity(id) {
            // 判断是否完善了个人信息
            let user = this.$store.getters.getUserInfo;
            if (user && user.name.length > 0 && user.phone.length > 0) {
                const { code, message } = await reserveActivity({ activityId: id });
                if (!code) {
                    return toast(message);
                }
                toast('预约成功');
                await this.getActivitiesForUser();
            } else {
                this.$dialog.confirm({
                    message: '请先去完善个人信息'
                }).then(() => {
                    this.$router.push('/patient/my/editUser');
                }).catch(() => {
                });
            }
        },
        async deleteActivityReservation(id) {
            const { code, message } = await deleteActivityReservation({ id });
            if (!code) {
                return toast(message);
            }
            toast('取消预约成功');
            await this.getActivitiesForUser();
        },
        getThumb(item) {
            return item.cover ? getImageUrl(item.cover) : this.eventsPhoto;
        }
    }
};
</script>

<style scoped>
.bookingBox {
    padding-bottom: 99px;
}

.bookCard {
    padding: 10px 0;
}

.borderBottom.van-cell::after {
    border-bottom: 1px solid #707070;
}

.bookCard .van-cell {
    font-size: 12px;
    padding: 8px 16px;
}

.tplMinor {
    color: #acacac;
}

.tplMain {
    color: #282a2d;
}

.bookingEmpty {
    color: #989898;
}

.BookingHave {
    color: #4ab8ab;
}

.newBook {
    height: 35px;
}

.tplButton {
    font-size: 12px;
    border: none;
    text-decoration: underline;
}

.activity-img {
    width: 100%;
    height: 138px;
}

.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
    padding: 0 15px;
}

.instrumentCard {
    background: #fff;
    margin: 10px 16px;
    overflow: hidden;
    border-radius: 8px;
}

.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.cardTileLeft {
    position: relative;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #4ab8ab;
}

.patientInfo {
    background: #f6f6f8;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
    padding: 10px 16px;
}

.borderDefault.chooseBox {
    border: 1px solid #00c3ae;
    overflow: hidden;
    border-radius: 8px;
}

.tplSerial {
    padding-right: 10px;
}

.tplSerial,
.tplTitle {
    font-size: 14px;
    font-weight: 600;
    color: #282a2d;
}

.distance, .duration {
    padding-top: 8px;
}

.tplTextDefault {
    display: inline-block;
    font-size: 14px;
    margin-left: 5px;
    color: #444;
}

.duration {
    padding-bottom: 5px;
}

.duration > .tplTextDefault {
    font-size: 12px !important;
}

.chooseBox .tplSerial,
.chooseBox .tplTitle {
    color: #00c3ae;
}

.timeChoose .van-tabs__nav {
    background: none;
}

.tplTextEmpty {
    font-size: 18px;
    color: #acacac;
    font-weight: 600;
}

.timeChoose {
    padding: 10px 0 0 0;
}

.timeChooseTitle {
    color: #282a2d;
    font-size: 15px;
    margin-bottom: 10px;
    padding: 0 15px;
}

.timeChoose .van-tab {
    font-size: 12px;
}

.timeChoose .van-tabs__nav {
    background: none;
}

.timeChoose .van-tabs__content {
    margin-top: 10px;
}

.timeChoose .van-tabs__wrap {
    padding: 0 15px;
}

.timeChoose .chooseBody {
    padding: 10px 15px;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.timeChoose .van-cell {
    padding: 8px 16px;
    border: 1px solid #979797;
    border-radius: 5px;
    margin: 5px 0;
}

.timeChoose .van-cell__title {
    font-size: 12px;
    color: #282a2d;
}

.radioText {
    color: #4ab8ab;
}

.radionNo {
    color: #888888;
}

.img-icon {
    width: 16px;
}

.flexAuto .van-cell__title,
.flexAuto .van-cell__value {
    flex: auto;
}

.events {
    background: #fff;
    margin: 10px 16px;
    border-radius: 10px;
    overflow: hidden;
}

.events .van-card__header {
    display: block;
}

.eventsState {
    padding: 10px 0;
    border-bottom: 1px solid #ebedf0;
    margin: 0 10px;
}

.events .van-card__title {
    font-size: 15px;
    color: #282a2d;
    line-height: 35px;
}

.events .van-cell {
    padding: 0;
    font-size: 12px;
    color: #6d7278;
    line-height: 24px;
}

.events .van-card__thumb img {
    border-radius: 0;
}

.emptyBox {
    background: #fff;
    margin: 10px 16px;
    border-radius: 8px;
}
</style>
