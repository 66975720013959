<template>
    <div>
        <van-cell-group class="mt10" inset v-for="(item, i) in reservations" :key="i">
            <van-cell class="borderBottom">
                <template #default>
                    <span class="BookingHave">预约成功</span>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="tplMinor">发起预约：</span>
                    <span class="tplMain">{{ item.createdAt }}</span>
                </template>
            </van-cell>
            <van-cell icon="location">
                <template #title>
                    <span class="tplMain ft15 ftb">{{ item.clinicName }}</span>
                </template>
            </van-cell>
            <van-cell class="ftb">
                <template #title>
                    <span class="tplMinor">就诊人：</span>
                    <span class="tplMain">{{ item.patient }}</span>
                </template>
            </van-cell>
            <van-cell class="ftb">
                <template #title>
                    <span class="tplMinor">就诊时间：</span>
                    <span class="tplMain">{{ item.day }} {{ periods[item.period] }}</span>
                </template>
            </van-cell>
            <van-cell class="ftb">
                <template #title>
                    <span class="tplMinor">联系人：</span>
                    <span class="tplMain">{{ item.clinicContact }}</span>
                </template>
            </van-cell>
            <van-cell class="ftb" center>
                <template #title>
                    <span class="tplMinor">电话 ：</span>
                    <span class="tplMain">{{ item.clinicPhone }}</span>
                    <span><a class="tplCopy" :href="'tel:' + item.clinicPhone">直接拨打</a></span>
                </template>
            </van-cell>

            <van-cell size="lager">
                <template #title>
                    <van-row type="flex" justify="center">
                        <van-col span="8" class="textC">
                            <van-button
                                class="tplButton Cancel"
                                plain
                                color="#666666"
                                @click="cancelClinicReservation(item.id)"
                                >取消预约
                            </van-button>
                        </van-col>
                    </van-row>
                </template>
            </van-cell>
        </van-cell-group>
        <van-empty v-if="!reservations.length" class="emptyBox" description="选择耳宁医疗中心，预约专业服务" />
        <van-cell class="textC bgNo bottomFixed" size="lager">
            <template #title>
                <van-button
                    class="newBook plr40"
                    round
                    color="#4AB8AB"
                    size="normal"
                    @click="toReservationClinicPage"
                    >新增预约
                </van-button>
            </template>
        </van-cell>
    </div>
</template>

<script>
import { cancelClinicReservation, getMyClinicReservations } from '@/services/reservation';
import { toast } from '@/util';
const periods = [
    null,
    '08:30-09:30',
    '09:30-10:30',
    '10:30-11:30',
    '13:00-14:00',
    '14:00-15:00',
    '15:00-16:00',
    '16:00-17:00',
    '17:00-18:00',
];
export default {
    name: 'clinic-list',
    data() {
        return {
            curSn: '',
            active: 0,
            radio: '1',
            timeActive: 1,
            activeIcon: require('@/assets/images/radioActive.png'),
            inactiveIcon: require('@/assets/images/radioDefault.png'),
            eventsPhoto: require('@/assets/images/events.png'),

            reservations: [],
            periods,
        };
    },
    created() {
        this.curSn = localStorage.getItem('curSn');
        this.getMyClinicReservations();
    },
    methods: {
        async getMyClinicReservations() {
            const { code, message, data } = await getMyClinicReservations(this.curSn);
            if (!code) {
                return toast(message);
            }
            this.reservations = data;
        },
        toReservationClinicPage() {
            // 判断是否完善了个人信息
            let user = this.$store.getters.getUserInfo;
            if (user && user.name.length > 0 && user.phone.length > 0) {
                this.$router.push('/patient/reservation/clinic');
            } else {
                this.$dialog.confirm({
                    message: '请先去完善个人信息'
                }).then(() => {
                    this.$router.push('/patient/my/editUser')
                }).catch(() => {
                });
            }
        },
        async cancelClinicReservation(id) {
            const { code, message } = await cancelClinicReservation({ id });
            if (!code) {
                return toast(message);
            }
            toast('取消预约成功');
            await this.getMyClinicReservations();
        },
    },
};
</script>

<style scoped>
.bookCard {
    padding: 10px 0;
}

.borderBottom.van-cell::after {
    border-bottom: 1px solid #707070;
}

.bookCard .van-cell {
    font-size: 12px;
    padding: 8px 16px;
}

.tplMinor {
    color: #acacac;
}

.tplMain {
    color: #282a2d;
}

.bookingEmpty {
    color: #989898;
}

.BookingHave {
    color: #4ab8ab;
}

.newBook {
    height: 35px;
}

.tplButton {
    font-size: 12px;
    border: none;
    text-decoration: underline;
}

.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
    padding: 0 15px;
}

.instrumentCard {
    background: #fff;
    margin: 10px 16px;
    overflow: hidden;
    border-radius: 8px;
}

.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.cardTileLeft {
    position: relative;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #4ab8ab;
}

.patientInfo {
    background: #f6f6f8;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
    padding: 10px 16px;
}

.borderDefault.chooseBox {
    border: 1px solid #00c3ae;
    overflow: hidden;
    border-radius: 8px;
}

.tplSerial {
    padding-right: 10px;
}

.tplSerial,
.tplTitle {
    font-size: 14px;
    font-weight: 600;
    color: #282a2d;
}

.tplTextDefault {
    font-size: 12px;
    margin-left: 5px;
    color: #acacac;
    font-weight: 100;
}

.chooseBox .tplSerial,
.chooseBox .tplTitle {
    color: #00c3ae;
}

.timeChoose .van-tabs__nav {
    background: none;
}

.tplTextEmpty {
    font-size: 18px;
    color: #acacac;
    font-weight: 600;
}

.timeChoose {
    padding: 10px 0 0 0;
}

.timeChooseTitle {
    color: #282a2d;
    font-size: 15px;
    margin-bottom: 10px;
    padding: 0 15px;
}

.timeChoose .van-tab {
    font-size: 12px;
}

.timeChoose .van-tabs__nav {
    background: none;
}

.timeChoose .van-tabs__content {
    margin-top: 10px;
}

.timeChoose .van-tabs__wrap {
    padding: 0 15px;
}

.timeChoose .chooseBody {
    padding: 10px 15px;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
}

.timeChoose .van-cell {
    padding: 8px 16px;
    border: 1px solid #979797;
    border-radius: 5px;
    margin: 5px 0;
}

.timeChoose .van-cell__title {
    font-size: 12px;
    color: #282a2d;
}

.radioText {
    color: #4ab8ab;
}

.radionNo {
    color: #888888;
}

.img-icon {
    width: 16px;
}

.flexAuto .van-cell__title,
.flexAuto .van-cell__value {
    flex: auto;
}

.events {
    background: #fff;
    margin: 10px 16px;
    border-radius: 10px;
    overflow: hidden;
}

.events .van-card__header {
    display: block;
}

.eventsState {
    padding: 10px 0;
    border-bottom: 1px solid #ebedf0;
    margin: 0 10px;
}

.events .van-card__title {
    font-size: 15px;
    color: #282a2d;
    line-height: 35px;
}

.events .van-cell {
    padding: 0;
    font-size: 12px;
    color: #6d7278;
    line-height: 24px;
}

.events .van-card__thumb img {
    border-radius: 0;
}
.bottomFixed {
    position: fixed;
    bottom: 100px;
    padding-bottom: env(safe-area-inset-bottom);
}
.emptyBox {
    background: #fff;
    margin: 10px 16px;
    border-radius: 8px;
}
</style>
