import { Method, request, Url } from '@/services/base';

async function getMyClinicReservations(sn) {
    return await request(Url.clinicReservation.getMyClinicReservations, Method.get, { sn });
}

async function getClinicWeeks({ clinicId }) {
    return await request(Url.clinicReservation.getClinicWeeks, Method.get, { clinicId });
}

async function reserveClinic({ clinicId, sn, day, period }) {
    return await request(Url.clinicReservation.reserveClinic, Method.post, {
        clinicId,
        sn,
        day,
        period,
    });
}

async function cancelClinicReservation({ id }) {
    return await request(Url.clinicReservation.cancelClinicReservation, Method.put, { id });
}

async function getActivitiesForUser() {
    return await request(Url.activity.getActivitiesForUser, Method.get);
}

async function reserveActivity({ activityId }) {
    return await request(Url.activityReservation.reserveActivity, Method.post, {
        activityId,
    });
}

async function deleteActivityReservation({ id }) {
    return await request(Url.activityReservation.deleteActivityReservation, Method.delete, { id });
}

export {
    getMyClinicReservations,
    getClinicWeeks,
    reserveClinic,
    cancelClinicReservation,
    getActivitiesForUser,
    reserveActivity,
    deleteActivityReservation,
};
