<template>
    <div class="myBooking">
        <div class="instrumentCard" v-if='curSn'>
            <div class="cardTile cell myBound">
                <div class="cardTileLeft">ID</div>
                <div class="cardTileRight cellFlex-1 textL">{{ curSn }}</div>
            </div>
            <div class="cardBody">
                <div class="userInfo">
                    <van-cell-group inset>
                        <van-cell class="bgNo" center>
                            <template #icon>
                                <van-image
                                    :src="curSnUser.patientWxHeadimgurl || userPhoto"
                                    width="35"
                                />
                            </template>
                            <template #title>
                                <span class="userName mlr10">{{ curSnUser.patientName }}</span>
                                <van-icon
                                    :name="curSnUser.patientSex === 1 ? sexNan : sexNv"
                                    class="sex-icon"
                                />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>
            </div>
        </div>
        <van-tabs v-model="active" sticky>
            <van-tab title="服务预约">
                <clinic-list></clinic-list>
            </van-tab>
            <van-tab title="活动预约">
                <activity-list></activity-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import ClinicList from '@/pages/patient/reservation/clinic-list';
import ActivityList from '@/pages/patient/reservation/activity-list';

export default {
    components: { ActivityList, ClinicList },
    name: 'index',
    data() {
        return {
            active: 0,
            curSn: '',
            curSnUser: {},
            isMember: true,
            myHomeTop: require('@/assets/images/my-home-top.png'),
            myHomeTopAdd: require('@/assets/images/my-home-top-add.png'),
            images: [
                require('@/assets/images/my-home-popularize.png'),
                require('@/assets/images/my-home-popularize.png'),
            ],
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
        };
    },
    created() {
        this.curSn = localStorage.getItem('curSn');
        if (this.curSn) {
            this.curSnUser = JSON.parse(localStorage.getItem('curSnUser'));
        }
    },
};
</script>

<style scoped>
.myBooking {
    /* padding-bottom: 80px; */
    padding-top: 1px;
    height: 100%;
    box-sizing: border-box;
}
.instrumentCard {
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
    margin: 10px;
}
.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.myBound.cardTile::after {
    background: #bebebe;
}

.erNing::before {
    content: 'ERNING';
    display: block;
    position: absolute;
    color: #acacac;
    font-size: 18px;
    top: 2px;
    left: 21px;
}

.cardTileLeft {
    position: relative;
    color: #acacac;
    font-weight: bold;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #282a2d;
    text-align: left;
    padding-left: 10px;
}

.cardBody .cardBodyLeft {
    font-size: 24px;
    color: #8d8d8d;
    font-weight: 600;
}

.cardBody .cardBodyRight {
    font-size: 24px;
    color: #282a2d;
    text-align: center;
}

.userInfo {
    padding: 10px;
}
.userInfo .van-cell__left-icon {
    font-size: 35px;
    height: auto;
    line-height: 100%;
}
.userName {
    font-size: 16px;
    color: #282a2d;
}
.sex-icon {
    font-size: 16px;
    vertical-align: middle;
}

.userInfo .van-cell-group {
    background: #f7f7f7;
    margin: 0;
}
</style>
